import Main from "components/Layout/Main";
import { SkipNavContent } from "components/SkipNav";
import { Card } from "components/frontpage/Card";
import useUser from "hooks/useUser";
import Grid from "@mui/material/Grid";
import { IFrontpage } from "data/contentData/api/content";
import { isSubscriber } from "auth/authorization";
export function FrontpageTemplate({
  frontpage
}: {
  frontpage: IFrontpage;
}) {
  const {
    user
  } = useUser();
  const _isSubscriber = isSubscriber(user?.roles);
  const cards = _isSubscriber ? frontpage.main.filter(c => !c.allowedRoles || c.allowedRoles.includes("Subscriber")) : frontpage.main.filter(c => !c.allowedRoles || c.allowedRoles?.includes("NonSubscriber"));
  if (!cards) {
    return null;
  }
  return <Main data-sentry-element="Main" data-sentry-component="FrontpageTemplate" data-sentry-source-file="FrontPageTemplate.tsx">
            <Grid container spacing={2} pt={2} data-sentry-element="Grid" data-sentry-source-file="FrontPageTemplate.tsx">
                <SkipNavContent data-sentry-element="SkipNavContent" data-sentry-source-file="FrontPageTemplate.tsx" />
                {cards.map(card => <Card key={card.contentId} {...card} />)}
            </Grid>
        </Main>;
}