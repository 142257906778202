import { Icon } from "components/Icon";
import { general } from "config/config";
import useGetAccessInfo from "hooks/useGetAccessInfo";
import { useGetPaywallType } from "hooks/useGetPaywallType";
import { t } from "locales";
import { useRouter } from "next/router";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IPage } from "data/contentData/api/content";
import { signIn } from "auth/signIn";
import theme from "lib/theme";
export default function Paywall({
  page
}: {
  page: IPage;
}) {
  const {
    authenticated
  } = useGetAccessInfo(page);
  const paywallType = useGetPaywallType(page);
  const router = useRouter();
  function handleLogin() {
    signIn({
      returnUrl: router.asPath
    });
  }
  if (paywallType === "none") {
    return null;
  }
  return <StyledContainer data-sentry-element="StyledContainer" data-sentry-component="Paywall" data-sentry-source-file="Paywall.tsx">
            <StyledContent data-sentry-element="StyledContent" data-sentry-source-file="Paywall.tsx">
                <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="Paywall.tsx">
                    {t["paywall"][paywallType]["title"]}{" "}
                </Typography>
                <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="Paywall.tsx">
                    {t["paywall"][paywallType]["text"]}
                </Typography>

                <StyledButtonContainer data-sentry-element="StyledButtonContainer" data-sentry-source-file="Paywall.tsx">
                    {!authenticated && <Button id="gtm-paywall-login" size="large" onClick={handleLogin} variant="text" startIcon={<Icon name="login" />}>
                            {t["profile"]["logged-out"]["login-button"]}
                        </Button>}

                    <Button id="gtm-paywall-order" href={general.orderPagesURL} size="large" variant="contained" color="secondary" data-sentry-element="Button" data-sentry-source-file="Paywall.tsx">
                        {t["paywall"]["order"]}
                    </Button>
                </StyledButtonContainer>
            </StyledContent>
        </StyledContainer>;
}
const StyledContainer = styled("div")(() => ({
  padding: theme.spacing(6),
  background: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  boxShadow: `0 8px 25px ${theme.palette.divider}`,
  position: "sticky",
  bottom: 0,
  zIndex: 1097,
  textAlign: "center"
}));
const StyledContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  margin: "0 auto",
  maxWidth: theme.constants.width.contentMax
}));
const StyledButtonContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column-reverse",
  gap: theme.spacing(2),
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    marginTop: theme.spacing(4)
  }
}));