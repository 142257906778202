import Collapsible from "components/Collapsible";
import PortableText from "components/PortableText/PortableText";
import { ISection } from "data/contentData/api/content";
interface IProps {
  section: ISection;
  noPrint?: boolean;
}
export default function CollapsibleContent({
  section,
  noPrint
}: IProps) {
  const {
    blockContent,
    url,
    name,
    heading,
    sections,
    collapsed
  } = section;
  return <Collapsible id={url} level={2} title={name} heading={heading} collapsed={collapsed} noPrint={noPrint} isTopPanel data-sentry-element="Collapsible" data-sentry-component="CollapsibleContent" data-sentry-source-file="CollapsibleContent.tsx">
            {blockContent ? <PortableText value={blockContent} wrapImgWithGalleryLink /> : null}
            {sections?.map(subSection => {
      const subSectionBlockContent = subSection.blockContent ? <PortableText key={subSection.url} value={subSection.blockContent} wrapImgWithGalleryLink /> : null;
      if (subSection.name) {
        return <Collapsible key={subSection.name} id={subSection.url} level={3} title={subSection.name} heading={subSection.heading} collapsed={subSection.collapsed}>
                            {subSectionBlockContent}
                        </Collapsible>;
      }
      return subSectionBlockContent;
    }) ?? null}
        </Collapsible>;
}