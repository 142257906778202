import Aside from "components/Layout/Aside";
import Main from "components/Layout/Main";
import Meta from "components/Meta";
import { SkipNavContent } from "components/SkipNav";
import { SitelistList } from "components/sitelist/SitelistList";
import usePath from "hooks/usePath";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IPage } from "data/contentData/api/content";
import { CONTENT_TYPE, getContentType } from "lib/get-content-type";
export function Sitelist({
  page
}: {
  page: IPage;
}) {
  const path = usePath();
  const articles = page.children?.filter(p => getContentType(p.types) !== CONTENT_TYPE.LIST) ?? [];
  const lists = page.children?.filter(p => getContentType(p.types) === CONTENT_TYPE.LIST) ?? [];
  const siblings = page.siblings?.filter(p => getContentType(p.types) === CONTENT_TYPE.LIST).map(({
    name,
    shortcut,
    url
  }) => ({
    name,
    url: shortcut ?? url,
    active: false
  })) ?? [];
  const activeSibling = siblings.find(p => p.url.startsWith(path));
  if (activeSibling) {
    activeSibling.active = true;
  }
  const title = page.parents?.[0].name ?? page.name;
  const listTitle = activeSibling?.name || title;
  return <Main leftAside={<Aside node={{
    name: title,
    nodes: siblings,
    url: path
  }} />} data-sentry-element="Main" data-sentry-component="Sitelist" data-sentry-source-file="Sitelist.tsx">
            <Meta isPublic title={title} url={path} data-sentry-element="Meta" data-sentry-source-file="Sitelist.tsx" />

            <Grid container data-sentry-element="Grid" data-sentry-source-file="Sitelist.tsx">
                <SkipNavContent data-sentry-element="SkipNavContent" data-sentry-source-file="Sitelist.tsx" />

                {activeSibling ? <Typography pt={1} mb={2} variant="h1">
                        {listTitle}
                    </Typography> : null}

                <SitelistList items={articles} data-sentry-element="SitelistList" data-sentry-source-file="Sitelist.tsx" />

                {lists.map(list => <SitelistList key={list.contentId} title={list.name} items={list.children} />)}
            </Grid>
        </Main>;
}