import { Icon } from "components/Icon";
import PortableText from "components/PortableText/PortableText";
import React from "react";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useGetBanner, useGetBanners } from "data/contentData/hooks/banner.hooks";
export function Banner() {
  const {
    data: banners
  } = useGetBanners();
  const {
    banner,
    handleClose
  } = useGetBanner(banners);
  if (!banner) return null;
  const severity = banner.bannerType === "warning" ? "warning" : "info";
  return <StyledAlert className="no-print" action={<StyledIconButton size="small" onClick={handleClose}>
                    <Icon name="close" fontSize="inherit" />
                </StyledIconButton>} severity={severity} icon={false} onClose={handleClose} data-sentry-element="StyledAlert" data-sentry-component="Banner" data-sentry-source-file="banner.tsx">
            <Container fixed data-sentry-element="Container" data-sentry-source-file="banner.tsx">
                <PortableText value={banner.blockContent} data-sentry-element="PortableText" data-sentry-source-file="banner.tsx" />
            </Container>
        </StyledAlert>;
}
const StyledAlert = styled(Alert)(({
  theme
}) => ({
  padding: theme.spacing(1, 2),
  alignItems: "center",
  "& .MuiAlert-action": {
    margin: 0,
    padding: 0
  },
  "& .MuiAlert-message": {
    flexGrow: 1,
    "& p": {
      margin: 0
    }
  }
}));
const StyledIconButton = styled(IconButton)(({
  theme
}) => ({
  padding: theme.spacing(1)
}));