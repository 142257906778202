import { PortableTextReactComponents } from "@portabletext/react";
import Link from "components/Link";
import { styled } from "@mui/material/styles";
export const frontpageCustomComponents = (color: string = "default"): Partial<PortableTextReactComponents> => {
  return {
    marks: {
      link: ({
        children,
        value
      }) => {
        return <StyledLink color={color} href={value.href} noWrap={false}>
                        {children}
                    </StyledLink>;
      }
    },
    list: ({
      children
    }) => <StyledUL>{children}</StyledUL>
  };
};
const StyledLink = styled(Link)<{
  color: string;
}>(({
  color,
  theme
}) => ({
  color: color === "primary" ? theme.palette.link.dark : theme.palette.link.light,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline"
  }
}));
const StyledUL = styled("ul")(({
  theme
}) => ({
  paddingInlineStart: theme.spacing(2.5)
}));