import PortableText from "components/PortableText/PortableText";
import CollapsibleContent from "components/article/CollapsibleContent";
import RegionalContent from "components/article/RegionalContent";
import SummaryContent from "components/article/SummaryContent";
import useUser from "hooks/useUser";
import { noPrintSectionTypes } from "pages/[[...page]]";
import { ISection } from "data/contentData/api/content";
export function ProfessionalPageSection({
  section,
  isPreview = false
}: {
  section: ISection;
  isPreview?: boolean;
}) {
  const {
    authenticated
  } = useUser();
  switch (section.type) {
    case "summary":
      {
        return <SummaryContent key={section.url} id={section.url} content={section.blockContent} />;
      }
    case "root":
      {
        return <PortableText key={section.url ?? "root"} value={section.blockContent} wrapImgWithGalleryLink />;
      }
    case "regional":
      if (!authenticated) return null;
      return <RegionalContent key={section.url} section={section} />;
    default:
      const _section = {
        ...section,
        collapsed: isPreview ? false : section.collapsed
      };
      return <CollapsibleContent key={_section.name} section={_section} noPrint={noPrintSectionTypes.includes(_section.type)} />;
  }
}