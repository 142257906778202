import { styled } from "@mui/material/styles";
export const PaywallBlur = styled("div")(({
  theme
}) => {
  const {
    contentMax,
    leftAside,
    rightAside
  } = theme.constants.width;
  const maxWidth = contentMax + leftAside + rightAside;
  return {
    background: "linear-gradient(#fffffff0,4%, #fff)",
    height: "100%",
    position: "absolute",
    top: 0,
    width: "100%",
    maxWidth: `min(100vw, ${maxWidth}px)`,
    zIndex: 1
  };
});