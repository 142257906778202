import Collapsible from "components/Collapsible";
import Link from "components/Link";
import { t } from "locales";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IReference } from "data/contentData/api/content";
interface IProps {
  id: string;
  references: IReference[];
  template?: "Professional" | "Default";
}
export function ReferencesSection({
  id,
  references,
  template = "Default"
}: IProps) {
  if (!references) {
    return null;
  }
  const _references = references.map(ref => {
    const cleanText = ref.text.replace(/[^a-zA-Z]/g, "").toLocaleLowerCase();
    const textId = cleanText.length > 3 ? cleanText.substring(0, 3) : "";
    return {
      key: `${ref.referenceId}-${textId}`,
      ...ref
    };
  });
  const title = t.article["references-title"];
  const refs = <Grid container component="ol">
            {_references.map(reference => <Reference key={reference.key} reference={reference} />)}
        </Grid>;
  if (template === "Professional") {
    return <Collapsible id={id} level={3} title={title}>
                {refs}
            </Collapsible>;
  }
  return <>
            <Typography variant="h3" gutterBottom data-sentry-element="Typography" data-sentry-source-file="ReferencesSection.tsx">
                {title}
            </Typography>
            {refs}
        </>;
}
interface IReferenceProps {
  reference: IReference;
}
function Reference({
  reference
}: IReferenceProps) {
  const {
    text,
    link
  } = reference;
  return <Grid item component="li" data-sentry-element="Grid" data-sentry-component="Reference" data-sentry-source-file="ReferencesSection.tsx">
            {text}
            {link && <Link display="block" href={link.href} title={link.title} color="secondary">
                    {`${link.text ?? new URL(link.href).host} `}
                </Link>}
        </Grid>;
}