import * as React from "react"

/**
 * This component needs to exist since we lazy-load content.
 * If there is a #hash we continiously try to scroll the element into view until 5 seconds
 * or the user interacts with the page
 */
export default function useEventualScroll() {
    React.useEffect(() => {
        if (!window.location.hash) {
            return
        }
        // This regex removes everything after ? and replaces # with "" since we now look after ids without the # prefix
        const hash = window.location.hash.replace(/(\?.*)|\#/, "")
        const el = document.getElementById(hash)
        if (!el) {
            return
        }

        const intervalId = setInterval(() => {
            el.scrollIntoView(true)
        }, 50)

        const timeoutId = setTimeout(() => {
            clearInterval(intervalId)
        }, 5000)

        const disconnect = () => {
            clearTimeout(timeoutId)
            clearInterval(intervalId)
        }

        window.addEventListener("mousedown", disconnect, { once: true })
        window.addEventListener("keydown", disconnect, { once: true })
        window.addEventListener("touchmove", disconnect, {
            once: true,
            passive: true
        })
        window.addEventListener("wheel", disconnect, {
            once: true,
            passive: true
        })
        return () => {
            disconnect()
            window.removeEventListener("mousedown", disconnect)
            window.removeEventListener("keydown", disconnect)
            window.removeEventListener("touchmove", disconnect)
            window.removeEventListener("wheel", disconnect)
        }
    }, [])
}
