import { useCallback, useLayoutEffect, useState } from "react"

// FROM https://github.com/juliankrispel/use-text-selection/blob/master/src/index.tsx

type ClientRect = DOMRect

function roundValues(_rect: ClientRect) {
    const rect = {
        ..._rect
    }
    for (const key of Object.keys(rect)) {
        // @ts-ignore
        rect[key] = Math.round(rect[key])
    }
    return rect
}

type TextSelectionState = {
    clientRect?: ClientRect
    isCollapsed?: boolean
    textContent?: string
}

const defaultState: TextSelectionState = {}

/**
 * useTextSelection()
 *
 * @description
 * hook to get information about the current text selection
 *
 */
export default function useTextSelection() {
    const [{ clientRect, isCollapsed, textContent }, setState] =
        useState<TextSelectionState>(defaultState)

    const reset = useCallback(() => {
        setState(defaultState)
    }, [])

    const handler = useCallback(() => {
        let newRect: ClientRect
        const selection = window.getSelection()
        const newState: TextSelectionState = {}

        if (selection == null || !selection.rangeCount) {
            setState(newState)
            return
        }

        const range = selection.getRangeAt(0)

        if (range == null) {
            setState(newState)
            return
        }

        const contents = range.cloneContents()

        if (contents.textContent != null) {
            newState.textContent = contents.textContent
        }

        const rects = range.getClientRects()

        if (rects.length === 0 && range.commonAncestorContainer != null) {
            const el = range.commonAncestorContainer as HTMLElement
            newRect = roundValues(el.getBoundingClientRect().toJSON())
        } else {
            if (rects.length < 1) return

            newRect = roundValues(rects[0].toJSON())
        }

        newState.clientRect = newRect
        newState.isCollapsed = range.collapsed

        setState(newState)
    }, [])

    useLayoutEffect(() => {
        document.addEventListener("selectionchange", handler)
        document.addEventListener("keydown", handler)
        document.addEventListener("keyup", handler)
        window.addEventListener("resize", handler)

        return () => {
            document.removeEventListener("selectionchange", handler)
            document.removeEventListener("keydown", handler)
            document.removeEventListener("keyup", handler)
            window.removeEventListener("resize", handler)
        }
    }, [handler])

    return {
        clientRect,
        isCollapsed,
        textContent,
        reset
    }
}
