import { useEffect, useState } from "react"

import { IPage } from "data/contentData/api/content"
import { isOpenAccess, isSubscriber } from "auth/authorization"
import { CONTENT_TYPE, getContentType } from "lib/get-content-type"

import useGetAccessInfo from "./useGetAccessInfo"

type PaywallType =
    | "article-public-open-not-logged-in"
    | "article-public-open-no-sub"
    | "article-public-not-logged-in"
    | "article-public-no-sub"
    | "not-logged-in"
    | "no-sub"
    | "none"

/**
 * Get paywall type for page and user
 * @param page the page to check hasReadAccess
 * @param user the user to check roles
 * @returns PaywallType
 */
export function useGetPaywallType(page: IPage): PaywallType {
    const [paywallType, setPaywallType] = useState<PaywallType>("none")
    const { isLoading, authenticated, roles } = useGetAccessInfo(page)

    useEffect(() => {
        const _paywallType = getPaywallType(
            isLoading,
            page.types,
            page.hasReadAccess,
            authenticated,
            roles
        )
        setPaywallType(_paywallType)
    }, [authenticated, roles, isLoading, page.hasReadAccess, page.types])

    return paywallType
}

function getPaywallType(
    isLoading: boolean,
    pageTypes: string[],
    hasReadAccess: string[],
    authenticated: boolean,
    roles: string[]
) {
    const contentType = getContentType(pageTypes)
    const _isOpenAccess = isOpenAccess(hasReadAccess)
    const _isSubscriber = isSubscriber(roles)

    if (isLoading) {
        return "none"
    }

    if (contentType === CONTENT_TYPE.ARTICLE_PUBLIC) {
        if (_isOpenAccess) {
            if (!authenticated) {
                return "article-public-open-not-logged-in"
            }

            if (authenticated && !_isSubscriber) {
                return "article-public-open-no-sub"
            }
        } else {
            if (!authenticated) {
                return "article-public-not-logged-in"
            }

            if (authenticated && !_isSubscriber) {
                return "article-public-no-sub"
            }
        }
    } else {
        if (_isOpenAccess) {
            return "none"
        }
    }

    if (!authenticated) {
        return "not-logged-in"
    }

    if (!_isSubscriber) {
        return "no-sub"
    }

    return "none"
}
