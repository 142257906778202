import { post } from "lib/http/http"

export async function refreshPage({ path }: { path: string }) {
    try {
        const { response } = await post<Response>(
            "/api/refresh-page",
            JSON.stringify(path)
        )

        return response as Response
    } catch (error) {
        throw new Error(
            `Error when trying to refresh page: ${JSON.stringify(error)}`
        )
    }
}
