import { PortableTextBlock } from "@portabletext/types";
import Collapsible from "components/Collapsible";
import PortableText from "components/PortableText/PortableText";
import useHashChange from "hooks/useHashChange";
import { t } from "locales";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { ISection } from "data/contentData/api/content";
import { useGetUserSettings, useUpdateUserSettings } from "data/contentData/hooks/user.hooks";
interface IProps {
  section: ISection;
}
export default function RegionalContent({
  section
}: IProps) {
  const {
    data: userSettings
  } = useGetUserSettings();
  const {
    isLoading
  } = useUpdateUserSettings();
  const userRegion = userSettings?.region ?? "none";
  const [expanded, setExpanded] = useState<string>("none");
  const handleExpand = (panel: string) => () => {
    setExpanded(expanded === panel ? "none" : panel);
  };
  const [sectionExpanded, setSectionExpanded] = useState(false);
  useHashChange(href => href.startsWith(section.url) && setSectionExpanded(true));
  return <Collapsible id={section.url} level={2} title={section.name} heading={section.heading} collapsed={userRegion === "none" && !sectionExpanded} data-sentry-element="Collapsible" data-sentry-component="RegionalContent" data-sentry-source-file="RegionalContent.tsx">
            {section.sections?.map((region, i) => {
      // If region.regions is undefiend/null, it means this regional content should be seen by any region
      // We give it a custom "region-{index}" to be able to toggle its expanded state
      const regionName = region.hasReadAccess?.[0] ?? `region-${i}`;
      return <Region key={region.url} expanded={userRegion === regionName || regionName === expanded} loading={isLoading} onExpand={handleExpand} userRegion={userRegion} regionName={regionName} {...region} />;
    }) ?? null}
        </Collapsible>;
}
interface RegionProps {
  expanded: boolean;
  regionName: string;
  url: string;
  name: string;
  heading: PortableTextBlock[];
  loading: boolean;
  blockContent: PortableTextBlock[];
  onExpand: (panel: string) => () => void;
  userRegion: string;
}
function Region(props: RegionProps) {
  const {
    name,
    heading,
    url,
    userRegion,
    regionName,
    loading,
    expanded,
    blockContent
  } = props;
  const collapsed = loading || !expanded;
  return <Collapsible key={url} collapsed={collapsed} id={url} level={3} title={<>
                    {name}
                    {userRegion === regionName ? <Typography component="span" textAlign="right" flex={1} mr={1}>
                            {t["regional-content"]["default"]}
                        </Typography> : null}
                </>} heading={heading} data-sentry-element="Collapsible" data-sentry-component="Region" data-sentry-source-file="RegionalContent.tsx">
            {regionName.startsWith("region-") ? null : <Typography align="right" p={0.5} color={"action.disabled"} component="span" variant="h6">
                    {regionName}
                </Typography>}
            <PortableText value={blockContent} data-sentry-element="PortableText" data-sentry-source-file="RegionalContent.tsx" />
        </Collapsible>;
}